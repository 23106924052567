import React from "react";
//Images
import imgR from "../images/R.png";
import imgH from "../images/H.png";
import imgU from "../images/U.png";
import imgP from "../images/P.png";
import imgS from "../images/S.png";
import imgD from "../images/D.png";
import imgB from "../images/B.png";
import imgJ from "../images/J.png";
import imgK from "../images/K.png";

class Panel extends React.Component {
  constructor(props) {
    super(props);
     /* CFGR-4 Data Cube is shown on Desktop Power Center as selection.  (Keystone cube should only be shown) */
  }
  
  determineAccept = (available) => {
    let acceptable = available;

    this.processSwitches(acceptable);
    this.processRecep(acceptable);
    // **********************************
    // Disabled for Launch (TBD)
    // **********************************
    // this.processSoft(acceptable);
    // this.processUSB(acceptable);
    /* CFGR-3 Do not publish Remote Soft-Tap Zigbee Switch cube to production,  HIDE functionality for now. */
    //this.processRemote(acceptable);
    this.processData(acceptable);
    this.processBreaker(acceptable);

    return acceptable
  }

  processSwitches = (options) => {
    const sIndex = options.findIndex(param => param.sku === "S");

    if (this.props.dashType === "D" ||
        this.props.record.isSoft === true ||
        this.props.record.USBC > 0 ||
        this.props.numberCubes < 3 || 
        this.props.record.switch >= 2 ||
        (this.props.numberCubes === 3 && this.props.record.switch >= 1) ||
        (this.props.record.recep + this.props.record.switch > 4) ||
        (this.props.record.data > 0 && this.props.record.switch !== 0)
    ) {
      options[sIndex].isShown = false;
    } else {
      options[sIndex].isShown = true;
    }
  }

  processRecep = (options) => {
    const rIndex = options.findIndex(param => param.sku === "R");
    const hIndex = options.findIndex(param => param.sku === "H");

    if (this.props.record.recep + this.props.record.switch <= 4) {
      options[rIndex].isShown = true;
      options[hIndex].isShown = true;
    } else {
     options[rIndex].isShown = false;
     options[hIndex].isShown = false;
    }
  }

  processUSB = (options) => {
    // const pIndex = options.findIndex(param => param.sku === "P");
    const wIndex = options.findIndex(param => param.sku === "6W");

    // if (this.props.dashType === "F" && this.props.record.USBC < 1) {
    //   options[pIndex].isShown = true;
    // } else {
    //   options[pIndex].isShown = false;
    // }
    
    if (this.props.record.USBC < 1 &&
        (this.props.numberCubes > this.props.currentCube + 1) &&
        this.props.choiceStore === null) {
      options[wIndex].isShown = true;
    } else {
      options[wIndex].isShown = false;
    }
  }
  
  processRemote = (options) => {
    const yIndex = options.findIndex(param => param.sku === "Y");

    if (this.props.record.remote <= 4) {
      options[yIndex].isShown = true;
    } else {
      options[yIndex].isShown = false;
    }
  }

  processSoft = (options) => {
    const dIndex = options.findIndex(param => param.sku === "D");
    const eIndex = options.findIndex(param => param.sku === "E");
    const zIndex = options.findIndex(param => param.sku === "Z");
    const gIndex = options.findIndex(param => param.sku === "G");

    if (this.props.dashType === "F" &&
        this.props.record.switch < 2 && 
        (this.props.record.switch + this.props.record.recep <= 4) &&
        (this.props.record.isSoft === true || this.props.record.isSoft === null)) {
      options[dIndex].isShown = true;
      options[eIndex].isShown = true;
      options[zIndex].isShown = true;
      options[gIndex].isShown = true;
    } else {
      options[dIndex].isShown = false;
      options[eIndex].isShown = false;
      options[zIndex].isShown = false;
      options[gIndex].isShown = false;
    }
  }
  processData = (options) => {
    let dataIndex = null;

    if (this.props.dashType === "F") {
      dataIndex = options.findIndex(param => param.sku === "J");
    } else if (this.props.dashType === "D") {
      dataIndex = options.findIndex(param => param.sku === "K");
    }

    if (this.props.isCordLeft) {
      if (this.props.dashType === "F") {
        if (this.props.numberCubes > 4 &&
           (this.props.numberCubes - this.props.currentCube <= 2 && this.props.record.switch <= 1)
           ) {
          options[dataIndex].isShown = true;
        } else if (
          this.props.numberCubes - this.props.currentCube === 1 &&
          this.props.record.switch < 2
          ) {
          options[dataIndex].isShown = true;
        } else {
          options[dataIndex].isShown = false;
        }
      }// else if (this.props.dashType === "D") {
      //   if (this.props.numberCubes !== 2 && (this.props.numberCubes - this.props.currentCube === 1)) {
      //     options[dataIndex].isShown = true;
      //   } else {
      //     options[dataIndex].isShown = false;
      //   }
      // }
    } else {
      if (this.props.currentCube === 1) {
        /* CFGR-2 able to select 2 Keystone cubes on the Desktop Power Center,  should only allow 1 Keystonen */
        if (this.props.data[0].contains.sku === "J") {
          options[dataIndex].isShown = true;
          return;
        } else {
          options[dataIndex].isShown = false;
          return;
        }
      }

      if (this.props.dashType === "F") {
        if (this.props.numberCubes >= 4 && this.props.currentCube <=1) {
          options[dataIndex].isShown = true;
        } else if (this.props.numberCubes === 3 && this.props.currentCube === 0) {
          options[dataIndex].isShown = true;
        } else {
          options[dataIndex].isShown = false;
        }
      } else if (this.props.dashType === "D") {
        if (this.props.numberCubes !== 2 && this.props.currentCube === 0) {
          options[dataIndex].isShown = true;
        } else {
          options[dataIndex].isShown = false;
        }
      }
    }

  }
  
  processBreaker = (options) => {
    const bIndex = options.findIndex(param => param.sku === "B");

    // if (this.props.currentCube + 1 === this.props.numberCubes && 
    //   this.props.numberCubes >= 5 &&
    //   this.props.record.breaker === 0) {
    //   options[bIndex].isShown = true;
    // } else {
      options[bIndex].isShown = false;
    //}
  }

  componentDidMount() {
    const breaker = this.state.availChoices.find(param => param.sku === "B")
    this.props.menuOnMount(breaker)
  }

  render() {
    /* Data Cube is shown on Desktop Power Center as selection.  (Keystone cube should only be shown) */
    let hoverMsg;
    if(this.props.dashType === "F") hoverMsg = "More than three receptacles and/or switches require a circuit breaker."
    if(this.props.dashType === "D") hoverMsg = "More than three receptacles requires a circuit breaker."
    this.state = {
      availChoices: [
        {
          isShown: true,
          sku: "R",
          image: imgR,
          name: "NEMA 5-15 Receptacle - Vertical Cube",
          note: null,
          hover: hoverMsg,
        },
        {
          isShown: true,
          sku: "H",
          image: imgH,
          name: "NEMA 5-15 Receptacle - Horizontal Cube",
          note: null,
          hover: hoverMsg,
        },
        {
          isShown: false,
          sku: "RU",
          image: "R",
          name: "Combination NEMA 5-15 Receptacle - Vertical / USB A/C - 15W Cube",
          note: null,
          hover: hoverMsg,
        },
        {
          isShown: true,
          sku: "U",
          image: imgU,
          name: "USB A/C - 15W Cube",
          note: null,
          hover: null,
        },
        {
          isShown: false,
          sku: "P",
          image: imgP,
          name: "USB C Power Delivery - 60W Cube",
          note: null,
          hover: null,
        },
        {
          isShown: false,
          sku: "6W",
          image: imgP,
          name: "USB C Power Delivery - 60W Cube (Size: 2 cubes)",
          note: null,
          hover: null,
        },
        {
          isShown: true,
          sku: "S",
          image: imgS,
          name: "Push Button Switch",
          note: null,
          hover: this.props.dashType === "F" ? hoverMsg : null,
        },
        {
          isShown: false,
          sku: "E",
          image: imgS,
          name: "Soft Tap Switch",
          note: null,
          hover: this.props.dashType === "F" ? hoverMsg : null,
        },
        {
          isShown: false,
          sku: "D",
          image: imgD,
          name: "Soft Tap Dimmer",
          note: null,
          hover: hoverMsg,
        },
        {
          isShown: false,
          sku: "Z",
          image: imgS,
          name: "Soft Tap Zigbee (Netatmo) Switch",
          note: null,
          hover: this.props.dashType === "F" ? hoverMsg : null,
        },
        /* CFGR-3 Do not publish Remote Soft-Tap Zigbee Switch cube to production,  HIDE functionality for now. */
        /* {
          isShown: true,
          sku: "Y",
          image: imgS,
          name: "Remote Soft-Tap Zigbee Switch",
          note: null,
          hover: "Five or more receptacles and/or switches require a circuit breaker.",
        }, */
        {
          isShown: false,
          sku: "G",
          image: imgD,
          name: "Soft Tap Zigbee (Netatmo) Dimmer",
          note: null,
          hover: hoverMsg,
        },
        {
          isShown: true,
          sku: "B",
          image: imgB,
          name: "Circuit Breaker Cube",
          note: null,
          hover: null,
        },
        {
          isShown: false,
          sku: "K",
          image: imgK,
          name: "Keystone Cube",
          note: null,
          hover: "Data cube must be placed on opposite side of cord exit. (Default: Left)",
        },
      ]
    }
    if(this.props.dashType === "F") {
      this.state.availChoices.push({
        isShown: false,
        sku: "J",
        image: imgJ,
        name: "Data Cube",
        note: null,
        hover: "Data cube must be placed on opposite side of cord exit. (Default: Left)",
      })
    }
    const choices = this.determineAccept(this.state.availChoices);
    const acceptChoices = choices.map((choice, i) => {
      if (choice.isShown) {
        return (
          <button
            className="container menu-choice tooltip"
            key={choice.sku}
            value={choice.value}
            onClick={() => {
              this.props.menuSelection(choice); 
            }}
          >
            <img src={choice.image} alt={choice.name} />
            <p>{choice.name}</p>
            {
              choice.hover
              &&
              <span class="tooltiptext">{choice.hover}</span>
            }
          </button>
        )
      }
    })

    return (
      <div className={"container" + (this.props.isMenuOpen ? " open" : "")} id="menu-panel" style={this.props.style}>
        <h2>Cube Options</h2>
        <p>Click choice for Cube #{this.props.currentCube + 1}</p>
        {
          this.props.dashType === "F"
          &&
          <p><b>Note: </b>
          {
            this.props.numberCubes > 3
            &&
            <span>More than three receptacles and/or switches require a circuit breaker. </span>
          }
          Maximum of two data cubes (Sizes larger than 3 cubes), which must be placed on opposite side of cord exit.</p>
        }
        {
          this.props.dashType === "D"
          &&
          <p><b>Note: </b>
          {
            this.props.numberCubes >= 5
            &&
            <span>More than three receptacles requires a circuit breaker. </span>
          }
          Data cube must be specified on left side of unit. Maximum of one data cube.</p>
        }
        <div id="choices" className="container hor">
          {acceptChoices}
        </div>
        {
          !this.props.choiceStore
          &&
          <button className="btn-general" onClick={() => this.props.prevStep()}>
            Previous 
            {
              this.props.currentCube === 0
              &&
              <span> Step</span>
            }
            {
              this.props.currentCube > 0
              &&
              <span> Cube</span>
            }
          </button>
        }
        {
          this.props.currentCube !== 0
          &&
          <button 
            className="btn-general"
            value="clear" 
            onClick={() => this.props.handleClearCubes()}
          >
            Clear Cubes
          </button>
        }
      </div>
    )
  }
}
export default Panel