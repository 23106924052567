import React from "react";
//Components
import Settings from "./Settings";
import Housing from "./Housing";
import Panel from "./Panel";

class Controller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashType: "F", // "F" - FPC/"D" - DPC
      numberCubes: 2, // 2/3/4/5
      isCordLeft: false, // false - Right/true - Left
      cordLength: 6, // 6/10
      passThru: "N", // "P" - Pass Thru/"N" - Non-Pass/"H" - Hardwire
      countryCode: "M", // "M" - Mexico/"C" - China
      sku: null,
      trimColor: null,
      trimSku: null,
      quantity: 1,
      data: [
        {
          location: 0,
          contains: {},
          isActive: true,
        },
        {
          location: 1,
          contains: {},
          isActive: true,
        },
        {
          location: 2,
          contains: {},
          isActive: true,
        },
        {
          location: 3,
          contains: {},
          isActive: true,
        },
        {
          location: 4,
          contains: {},
          isActive: true,
        },
        {
          location: 5,
          contains: {},
          isActive: true,
        },
      ],
      record: {
        recep: 0,
        USBC: 0,
        switch: 0,
        breaker: 0,
        remote: 0,
        data: 0,
        isSoft: null,
      },
      step: 0,
      currentCube: 0,
      isMenuOpen: false,
      isAlertOpen: false,
      isDAlertOpen: false,
      cubeStep: 3,
      choiceStore: null,
      breakerStore: null,
      isMirrorOk: true,
      state: null,
      skus: [],
    }
  } 

  menuOnMount = (breaker) => {
    this.setState({
      breakerStore: breaker
    })
  }

  handleTypeChange = (e) => {
    this.setState({
      dashType: e.target.value
    })
  }

  handlePassChange = (e) => {
    this.setState({
      passThru: e.target.value
    }, () => this.calcSku())
  }

  handleCountryChange = (e) => {
    this.setState({
      countryCode: e.target.value
    }, () => this.calcSku())
  }

  handleTrimChange = (value) => {
    document.documentElement.style.setProperty("--trim-color", "var(" + value + ")");
    let trimColor = null

    switch (value) {
      case "--default":
        trimColor = "N/A";
        break;
      case "--brass":
        trimColor = "BR";
        break;
      case "--bronze":
        trimColor = "BZ";
        break;
      case "--stainless":
        trimColor = "ST";
        break;
    
      default:
        break;
    }

    this.setState({
      trimColor: trimColor
    })
  }

  handleSelectChange = (e) => {
    this.setState({
      numberCubes: parseInt(e.target.value)
    })
  }

  handleQtyChange = (e) => {
    let qty = parseInt(e.target.value.slice(0, 3));
    if (qty < 1) {
      this.setState({
        quantity: 1
      })
    } else {
      this.setState({
        quantity: qty
      })
    }
  }

  handleCordChange = (e) => {
    if(parseInt(e.target.value)) {
      this.setState({
        cordLength: parseInt(e.target.value)
      })
    } else {
      const isLeft = (e.target.value === "Left") ? true : false;
      this.setState({
        isCordLeft: isLeft
      })
    }
  }

  handleClearCubes = () => {
    this.setState({
      data: this.state.state.data,
      numberCubes: this.state.state.numberCubes,
      currentCube: 0,
      choiceStore: null,
    }, () => {this.countCubes()})
  }

  prevStep = () => {
    if (this.state.currentCube !== 0) {
      if (this.state.currentCube >= 2 && 
          this.state.data[this.state.currentCube-2].contains.sku === "6W") {
        this.setState(prevState => ({
          data: [
            ...this.state.data.slice(0,this.state.currentCube-2),
            { ...this.state.data[this.state.currentCube-2], 
              contains: {}
            },
            { ...this.state.data[this.state.currentCube-1], 
              contains: {},
              isActive: true
            },  
            ...this.state.data.slice(this.state.currentCube)
          ],
          currentCube: prevState.currentCube - 2
        }), () => {this.countCubes()});
      } else {
        this.setState(prevState => ({
          data: [
            ...this.state.data.slice(0,this.state.currentCube-1),
           { ...this.state.data[this.state.currentCube-1], 
              contains: {}
            },  
            ...this.state.data.slice(this.state.currentCube)
          ],
          currentCube: prevState.currentCube - 1
        }), () => {this.countCubes()});
      }




    } else {
      this.setState(prevState => ({
        isMenuOpen: false,
        step: prevState.step - 1
      }))
    }
  }

  nextStep = () => {
    this.setState(prevState => ({
        step: prevState.step + 1
    }), () => {
      if (this.state.step === this.state.cubeStep) {
        let cubeState = {
          data: this.state.data.slice(),
          numberCubes: this.state.numberCubes
        }

        this.setState({
          isMenuOpen: true,
          passThru: (this.state.dashType === "F" ? "N" : "X"),
          state: cubeState
        })
      } else {
        this.setState({
          isMenuOpen: false
        })
      }
    })
  }

  menuSelection = (choice) => {
    if (!this.checkBreaker(choice) || !this.checkData(choice)) {
      return
    }

    this.setState({
      data: [
        ...this.state.data.slice(0,this.state.currentCube),
       { ...this.state.data[this.state.currentCube], contains: choice },  
        ...this.state.data.slice(this.state.currentCube+1)
      ]
    }, () => {
      this.countCubes();
      this.calcSku();

      if (this.state.currentCube + 1 < this.state.numberCubes) {
        let jump = this.check6W(choice);
        this.setState(prevState => ({
          currentCube: prevState.currentCube + jump
        }), () => {
          if (this.state.currentCube + 1 > this.state.numberCubes) {
            this.nextStep();
          }
        })
      } else {
        this.nextStep();
      }
    })
  }

  check6W = (choice) => {
    if (choice.sku === "6W") {
      this.setState({
        data: [
          ...this.state.data.slice(0,this.state.currentCube+1), 
         { ...this.state.data[this.state.currentCube+1], isActive: false },  
          ...this.state.data.slice(this.state.currentCube+2)
        ]
      });
      return 2;
    } else {
      return 1;
    }
  }

  checkData = (choice) => {
    if ((this.state.numberCubes - this.state.currentCube === 2) &&
        (choice.sku === "J" || 
          choice.sku === "K"
        ) &&
        this.state.choiceStore === null &&
        this.state.isCordLeft === true
    ) {
      this.setState({
        isDAlertOpen: true,
        isMenuOpen: false,
        choiceStore: choice,
      })
      return false;
    }

    return true;
  }

  handleDataChoice = (e) => {
    switch (e.target.value) {
      case "replace":
        this.setState({
          data: [
            ...this.state.data.slice(0,this.state.numberCubes-2),
           { ...this.state.data[this.state.numberCubes-2], 
              contains: this.state.choiceStore
             },  
            ...this.state.data.slice(this.state.numberCubes-1)
          ]
        }, this.setState(prevState => ({
          currentCube: prevState.currentCube + 1
        }), () => {this.menuSelection(this.state.choiceStore)}));
        break;
      case "cancel":
        this.setState({
          choiceStore: null
        })
        break;
    
      default:
        break;
    }
    
    this.setState({
      isDAlertOpen: false,
      isMenuOpen: true,
    })
  }

  checkBreaker = (choice) => {
    if (this.state.record.recep + this.state.record.switch === 3 &&
        (choice.sku === "R" || 
          choice.sku === "H" || 
          choice.sku === "RU" || 
          choice.sku === "S" || 
          choice.sku === "D"
        ) &&
        this.state.dashType === 'F'
        &&
        this.state.choiceStore === null
    ) {
      this.setState({
        isAlertOpen: true,
        isMenuOpen: false,
        choiceStore: choice,
      })
      return false;
    }
    else if (this.state.record.recep  === 3 &&
      (choice.sku === "R" || 
        choice.sku === "H" || 
        choice.sku === "RU" ||  
        choice.sku === "D"
      ) &&
      this.state.dashType === 'D'
      &&
      this.state.choiceStore === null
  ) {
    this.setState({
      isAlertOpen: true,
      isMenuOpen: false,
      choiceStore: choice,
    })
    return false;
  }

    return true;
  }

  handleBreakerChoice = (e) => {
    if (this.state.isCordLeft) {
      let oldData = this.state.data;
      switch (e.target.value) {
        case "replace":
          oldData[0].contains = this.state.breakerStore;
          this.menuSelection(this.state.choiceStore)
          
          break;
        case "expand":
          oldData.pop();
          oldData.unshift(
            {
              location: 0,
              contains: this.state.breakerStore,
              isActive: true,
            });

          this.setState(prevState => ({
            currentCube: prevState.currentCube + 1,
            numberCubes: prevState.numberCubes + 1
          }), () => {this.menuSelection(this.state.choiceStore)})
  
          break;
        case "shift":
          oldData.pop();
          oldData.unshift(
            {
              location: 0,
              contains: this.state.breakerStore,
              isActive: true,
            });


          this.setState(prevState => ({
            currentCube: prevState.currentCube + 1
          }), () => {
            this.menuSelection(this.state.choiceStore);
            if (this.state.numberCubes === 5) {
              oldData[this.state.currentCube+1].isActive = false;
            }
          })
          
  
          break;
        case "cancel":
          this.setState({
            choiceStore: null
          })
          break;
      
        default:
          break;
      }
    } else {
      switch (e.target.value) {
        case "replace":
          this.setState({
            data: [
              ...this.state.data.slice(0,this.state.numberCubes-1),
             { ...this.state.data[this.state.numberCubes-1], 
                contains: this.state.breakerStore
               },  
              ...this.state.data.slice(this.state.numberCubes)
            ]
          }, this.setState(prevState => ({
            numberCubes: prevState.numberCubes - 1
          }), () => {this.menuSelection(this.state.choiceStore)}));
          
          break;
        case "expand":
          this.setState({
            data: [
              ...this.state.data.slice(0,this.state.numberCubes),
             { ...this.state.data[this.state.numberCubes], 
                contains: this.state.breakerStore, isActive: true
               },  
              ...this.state.data.slice(this.state.numberCubes+1)
            ]
          }, () => {this.menuSelection(this.state.choiceStore)});
  
          break;
        case "cancel":
          this.setState({
            choiceStore: null
          })
          break;
      
        default:
          break;
      }
    }

    this.setState({
      isAlertOpen: false,
      isMenuOpen: true,
    })
  }
  
  countCubes = () => {
    let recep = 0;
    let switches = 0;
    let breaker = 0;
    let remote = 0;
    let USBC = 0;
    let data = 0;
    let isSoft = null;
    this.state.data.forEach(cube => {
      switch (cube.contains.sku) {
        case "R":
          recep++;
          break;

        case "H":
          recep++;
          break;

        case "RU":
          recep++;
          break;

        case "P":
          USBC++;
          break;

        case "6W":
          USBC++;
          break;

        case "S":
          switches++;
          isSoft = false;
          break;

        case "E":
          switches++;
          isSoft = true;
          break;

        case "Z":
          switches++;
          isSoft = true;
          break;

        case "Y":
          remote++;
          break;

        case "D":
          switches++;
          isSoft = true;
          break;

        case "G":
          switches++;
          isSoft = true;
          break;

        case "B":
          breaker++;
          break;

        case "J":
          data++;
          break;

        case "K":
          data++;
          break;
      
        default:
          break;
      }
    });
    this.setState({
      record: {
        recep: recep,
        switch: switches,
        breaker: breaker,
        USBC: USBC,
        isSoft: isSoft,
        remote: remote,
        data: data,
      }
    })
  }

  calcSku = (flip) => {
    flip = flip || false;
    let cubeSku = this.state.data.map((cube) => {
      return cube.contains.sku
    })

    const cordSide = flip ? (!this.state.isCordLeft ? "L" : "R") : (this.state.isCordLeft ? "L" : "R");
    const color = getComputedStyle(document.documentElement).getPropertyValue("--default").trim();
    const colorSku = (color === "#e7e8e8") ? "W" : "G";

    const newSku = 
      this.state.dashType +
      (this.state.choiceStore && !this.state.isCordLeft ? (this.state.numberCubes + 1).toString() : this.state.numberCubes.toString()) +
      // OLD "BUILD TO CORD" LOGIC
      (flip ? cubeSku.reverse().join("") : cubeSku.join("")) +
      this.state.cordLength.toString() +
      this.state.passThru +
      cordSide +
      colorSku +
      this.state.countryCode;


    const newTrim = (this.state.trimColor === null || this.state.trimColor === "N/A") ? "N/A" :
      "TRIM" +
      this.state.numberCubes +
      "-" +
      this.state.trimColor;

    this.setState({
      sku: newSku,
      trimSku: newTrim
    })
  }

  createNewDash = (mod) => {
    this.calcSku();
    let skus = this.state.skus;
    let quantity = this.state.quantity;
    let trim = this.state.trimSku;
    skus.push({sku: this.state.sku, trim: trim, qty: quantity});

    if (mod === "mirror") {
      this.calcSku(true);
      this.setState({
        isMirrorOk: false
      })
    } else {
      document.documentElement.style.setProperty("--trim-color", "var(--default)");
      this.setState({
        dashType: "F", // "F" - FPC/"D" - DPC
        numberCubes: 2, // 2/3/4/5
        isCordLeft: false, // false - Right/true - Left
        cordLength: 6, // 6/10
        passThru: "N", // "P" - Pass Thru/"N" - Non-Pass/"H" - Hardwire
        countryCode: "M", // "M" - Mexico/"C" - China
        sku: null,
        trimColor: null,
        trimSku: null,
        quantity: 1,
        data: [
          {
            location: 0,
            contains: {},
            isActive: true,
          },
          {
            location: 1,
            contains: {},
            isActive: true,
          },
          {
            location: 2,
            contains: {},
            isActive: true,
          },
          {
            location: 3,
            contains: {},
            isActive: true,
          },
          {
            location: 4,
            contains: {},
            isActive: true,
          },
          {
            location: 5,
            contains: {},
            isActive: true,
          },
        ],
        record: {
          recep: 0,
          USBC: 0,
          switch: 0,
          breaker: 0,
          remote: 0,
          data: 0,
          isSoft: null,
        },
        step: 0,
        currentCube: 0,
        isMenuOpen: false,
        isAlertOpen: false,
        isDAlertOpen: false,
        cubeStep: 3,
        choiceStore: null,
        isMirrorOk: true,
        state: null,
      });
    }

  }

  render() {
    return (
      <div id="controller" className="container">
        <Settings 
          step={this.state.step}
          onPick={this.handleTypeChange}
          dashType={this.state.dashType}
          onPass={this.handlePassChange}
          passThru={this.state.passThru}
          onCountry={this.handleCountryChange}
          countryCode={this.state.countryCode}
          onChange={this.handleSelectChange} 
          numberCubes={this.state.numberCubes}
          onCheck={this.handleCordChange} 
          isCordLeft={this.state.isCordLeft}
          cordLength={this.state.cordLength}
          calcSku={this.calcSku}
          record={this.state.record}
          createNewDash={this.createNewDash}
          skus={this.state.skus}
          isMirrorOk={this.state.isMirrorOk}
          sku={this.state.sku}
          onQty={this.handleQtyChange}
          qty={this.state.quantity}
          onTrim={this.handleTrimChange}
          trimSku={this.state.trimSku}
          trimColor={this.state.trimColor}
        />
        <div className="container hor">
          {
            this.state.step > 0
            &&
            this.state.step < this.state.cubeStep
            &&
            <button className="btn-general" onClick={() => this.prevStep()}>
              Previous
            </button>
          }
          {
            this.state.step !== this.state.cubeStep
            &&
            this.state.step < 5
            &&
            <button className="btn-general" onClick={() => this.nextStep()}>
              Next
            </button>
          }
        </div>
        { 
          this.state.step >= this.state.cubeStep
          &&
          <div className="container">
            <Housing 
              dashType={this.state.dashType} 
              numberCubes={this.state.numberCubes}
              isCordLeft={this.state.isCordLeft}
              data={this.state.data}
              currentCube={this.state.currentCube}
              switches={this.state.record.switch}
              calcSku={(cubes) => {this.calcSku(cubes)}}
            /> 
          </div>
        }
        <Panel
          menuOnMount={this.menuOnMount}
          menuSelection={this.menuSelection}
          handleClearCubes={this.handleClearCubes}
          prevStep={this.prevStep}
          dashType={this.state.dashType}
          data={this.state.data}
          currentCube={this.state.currentCube}
          numberCubes={this.state.numberCubes}
          record={this.state.record}
          isMenuOpen={this.state.isMenuOpen}
          choiceStore={this.state.choiceStore}
          isCordLeft={this.state.isCordLeft}
        />
        <div id="recep-choice" className={"container" + (this.state.isAlertOpen ? " open" : "")}>
        {this.state.dashType === 'F' && <p>More than three receptacles and/or switches require a Circuit Breaker. The breaker can only be placed in the cube slot closest to the cord. </p> }
        {this.state.dashType === 'D' && <p>More than three receptacles requires a circuit breaker. The breaker can only be placed in the cube slot closest to the cord. </p> }
        <p>
            Would you like
            {
              this.state.numberCubes > 4
              &&
              (
                (
                  (this.state.isCordLeft === false) &&
                  (
                    (this.state.numberCubes === 5 && this.state.currentCube < 4) ||
                    (this.state.numberCubes === 6 && this.state.currentCube < 5)
                  )
                )
                ||
                (this.state.isCordLeft === true)
              )
              &&
              <span> to replace the cube slot closest to the cord with a Circuit Breaker, or</span>
            }
            {
              this.state.isCordLeft === true
              &&
              (
                (this.state.numberCubes === 6 && this.state.currentCube < 5)
              )
              &&
              <span> to shift the cubes right and add the Circuit Breaker, or</span>
            }
            {
              this.state.numberCubes < 6
              &&
              <span> to expand the size to {this.state.numberCubes + 1}, or</span>
            }
            <span> to cancel adding this cube and revise configuration as necessary.</span>
          </p>
          <div className="container hor">
            {
              this.state.numberCubes > 4
              &&
              (
                (
                  (this.state.isCordLeft === false) &&
                  (
                    (this.state.numberCubes === 5 && this.state.currentCube < 4) ||
                    (this.state.numberCubes === 6 && this.state.currentCube < 5)
                  )
                )
                ||
                (this.state.isCordLeft === true)
              )
              &&
              <button 
                className="btn-general"
                value="replace" 
                onClick={(e) => {this.handleBreakerChoice(e)}}
              >
                Replace
              </button>
            }
            {
              this.state.numberCubes > 4
              &&
              this.state.isCordLeft === true
              &&
              (
                (this.state.numberCubes === 5 && this.state.currentCube < 3) ||
                (this.state.numberCubes === 6 && this.state.currentCube < 5)
              )
              &&
              <button 
                className="btn-general"
                value="shift" 
                onClick={(e) => {this.handleBreakerChoice(e)}}
              >
                Shift
              </button>
            }
            {
              this.state.numberCubes < 6
              &&
              <button 
                className="btn-general"
                value="expand" 
                onClick={(e) => {this.handleBreakerChoice(e)}}
              >
                Expand
              </button>
            }
            <button 
              className="btn-general"
              value="cancel" 
              onClick={(e) => {this.handleBreakerChoice(e)}}
            >
              Cancel
            </button>
          </div>
        </div>
        <div id="data-choice" className={"container" + (this.state.isDAlertOpen ? " open" : "")}>
          <p>Selecting Data for this location requires a second Data in the last cube slot. Replace the final cube with a second data, or cancel current selection?</p>
          <div className="container hor">
            {
              true
              &&
              <button 
                className="btn-general"
                value="replace" 
                onClick={(e) => {this.handleDataChoice(e)}}
              >
                Replace
              </button>
            }
            <button 
              className="btn-general"
              value="cancel" 
              onClick={(e) => {this.handleDataChoice(e)}}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default Controller