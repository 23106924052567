import React from "react";

class Cube extends React.Component {
  render() {
    let width = "var(--cube-size)";
    if (this.props.contains && this.props.contains.sku === "6W") {
      width = "calc(2 * var(--cube-size))"
    }

    return (
      <div 
        className="container cube" 
        style={this.props.isActive ? 
          {
            width: width,
            backgroundImage: "url(" + this.props.contains.image + ")",
            backgroundColor:  (Object.keys(this.props.contains).length > 0) ?  "var(--cube-color)" : "var(--logo-color)"
          }
          : 
          {display: "none"}
        }
      >
      {
        this.props.isCurrent
        &&
        Object.keys(this.props.contains).length === 0
        &&
        <p>Current Selection</p>
      }
      </div>
    )
  }
}
export default Cube