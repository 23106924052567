import React from "react";
//Components
import Header from "./components/Header";
import Controller from "./components/Controller";

class App extends React.Component {
  render() {
    return (
      <div className="container">
        <Header />
        <Controller />
      </div>
    )
  }
}
export default App