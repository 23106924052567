import React from "react";
import { Page, Text, Image, View, Document, StyleSheet, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// Images
import img2 from "../images/Size2.png";
import img3 from "../images/Size3.png";
import img4 from "../images/Size4.png";
import img5 from "../images/Size5.png";
import img6 from "../images/Size6.png";
import img2D from "../images/Size2D.png";
import img3D from "../images/Size3D.png";
import img4D from "../images/Size4D.png";
import img5D from "../images/Size5D.png";
import img6D from "../images/Size6D.png";
import imgLeft from "../images/Left.png";
import imgRight from "../images/Right.png";
import img6ft from "../images/Sixft.png";
import img6ftD from "../images/img6ftD.png";
import img10ftD from "../images/img10ftD.png";
import imgFCP from "../images/FCP.png";
import imgDCP from "../images/DCP.png";
import imgPass from "../images/Pass.png";
import imgNonPass from "../images/NonPass.png";
import imgNonPassBraid from "../images/NonPassBraid.png";
import imgNA from "../images/ShipNA.png";
import imgOther from "../images/ShipOther.png";
import imgLogo from "../images/logo-legrand.png";



// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "10px",
  },
  section: {
    flexDirection: "row",
    padding: "0px 10px 3px 10px"
  },
  darkSection: {
    flexDirection: "row",
    padding: "0px 10px 3px 10px",
    backgroundColor: "#F6F6F6"
  },
  lineSection: {
    flexDirection: "row",
    padding: "0px 10px 0px 10px",
    borderBottom: "1px solid #000"
  },
  column: {
    flexDirection: "column",
    width: "25%",
    padding: "5px 5px 0px 5px",
    fontSize: "10px",
    flexGrow: 1
  },
  wideColumn: {
    flexDirection: "column",
    width: "40%",
    padding: "5px 5px 0px 5px",
    fontSize: "10px",
    flexGrow: 2
  },
  thinColumn: {
    flexDirection: "column",
    width: "5%",
    padding: "5px 5px 0px 5px",
    fontSize: "10px",
    flexGrow: 1
  },
  heading: {
    alignSelf: "center",
    paddingLeft: "25px",
    fontSize: "25px",
    fontWeight: "bold"
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "94px",
    height: "71px",
    padding: "10px",
    backgroundColor: "#ed413d",
  },
  form: {
    flexDirection: "column",
    margin: "10px",
    padding: "10px",
    border: "1px solid #000"
  },
  formHeading: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "bold"
  },
});
// Create Document Component
const MyDocument = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.image}>
          <Image src={imgLogo}></Image>
        </View>
        <Text style={styles.heading}>Dash Configurator SKU List</Text>
      </View>
      <View style={styles.form}>
        <View style={styles.section}>
          <Text style={styles.formHeading}>Contact Information:</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text>First:</Text>
            <Text>{props.fName}</Text>
          </View>
          <View style={styles.column}>
            <Text>Last:</Text>
            <Text>{props.lName}</Text>
          </View>
          <View style={styles.wideColumn}>
            <Text>Email:</Text>
            <Text>{props.email}</Text>
          </View>
          <View style={styles.column}>
            <Text>Phone:</Text>
            <Text>{props.phone}</Text>
          </View>
        </View>
      </View>
      <View style={styles.lineSection}>
        <View style={styles.section}>
          <View style={styles.wideColumn}>
            <Text>Description:</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text>SKU:</Text>
          </View>
          <View style={styles.column}>
            <Text>Trim:</Text>
          </View>
          <View style={styles.thinColumn}>
            <Text>Qty:</Text>
          </View>
        </View>
      </View>

      {props.data.skus.map((sku, id) => {
            return (
              <View key={"list"+id} style={(id % 2 === 0) ? styles.section : styles.darkSection}>
                <View style={styles.section}>
                  <View style={styles.wideColumn}>
                    <Text key={"desc"+id}>{props.calcDesc(sku.sku, sku.trim)}</Text>
                  </View>
                </View>
                <View style={styles.section}>
                  <View style={styles.column}>
                    <Text key={"sku"+id}>{sku.sku}</Text>
                  </View>
                  <View style={styles.column}>
                    <Text key={"trim"+id}>{sku.trim}</Text>
                  </View>
                  <View style={styles.thinColumn}>
                    <Text key={"qty"+id}>{sku.qty}</Text>
                  </View>
                </View>
              </View>
            );
      })}
      <View style={(props.data.skus.length % 2 === 0) ? styles.section : styles.darkSection}>
        <View style={styles.section}>
          <View style={styles.wideColumn}>
            <Text>{props.calcDesc(props.data.sku, props.data.trimSku)}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text>{props.data.sku}</Text>
          </View>
          <View style={styles.column}>
            <Text>{props.data.trimSku}</Text>
          </View>
          <View style={styles.thinColumn}>
            <Text>{props.data.qty}</Text>
          </View>
        </View>
      </View>
      
    </Page>
  </Document>
);

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      needsReset: false,
      color: "white",
      trim: "default",
      fName: "",
      lName: "",
      email: "",
      phone: "",
      //notes: "",
    }  
  }


  handleColorChange = (value) => {
    document.documentElement.style.setProperty("--default", "var(" + value + ")");
    if (value === "--white") {
      this.setState({
        color: "white"
      })
    } else {
      this.setState({
        color: "graphite"
      })
    }
  }

  handleTextChange = (value) => {
    switch (value.target.name) {
      case "first":
        this.setState({
          fName: value.target.value
        })
        break;
      case "last":
        this.setState({
          lName: value.target.value
        })
        break;
      case "email":
        this.setState({
          email: value.target.value
        })
        break;
      case "phone":
        this.setState({
          phone: value.target.value
        })
        break;
      // case "notes":
      //   this.setState({
      //     notes: value.target.value
      //   })
      //   break;
    
      default:
        break;
    }
  }

  calcDesc = (sku, trim) => {
    const splitSku = sku.split("");
    let head = 0;
    let maxCubes = 2;
    let desc = "";

    switch (splitSku[head]) {
      case "F":
        desc += "Furniture Power Center, ";
        break;
      case "D":
        desc += "Desktop Power Center, "
        break;
    
      default:
        break;
    }

    desc += splitSku[1] + " Cubes, "
    maxCubes += parseInt(splitSku[1]);
    
    for (let i = 2; i < maxCubes; i++) {
      const cube = splitSku[i];
      switch (cube) {
        case "R":
          desc += "NEMA 5-15 Receptacle - Vertical Cube, "
          break;

        case "H":
          desc += "NEMA 5-15 Receptacle - Horizontal Cube, "
          break;

        case "U":
          desc += "USB A/C - 15W Cube, "
          break;

        case "P":
          desc += "USB C Power Delivery - 60W Cube, "
          break;

        case "6":
          desc += "USB C Power Delivery - 60W Cube (Size: 2 cubes), "
          i++;
          break;

        case "S":
          desc += "Push Button Switch, "
          break;

        case "E":
          desc += "Soft Tap Switch, "
          break;

        case "Z":
          desc += "Soft Tap Dimmer, "
          break;

        case "D":
          desc += "Soft Tap Zigbee (Netatmo) Switch, "
          break;

        case "G":
          desc += "Soft Tap Zigbee (Netatmo) Dimmer, "
          break;

        case "B":
          desc += "Circuit Breaker Cube, "
          break;

        case "J":
          desc += "Data Cube, "
          break;

        case "K":
          desc += "Keystone Cube, "
          break;
      
        default:
          break;
      }
      
    }

    head = maxCubes;

    switch (splitSku[head]) {
      case "6":
        desc += "6ft Cord Length, "
        break;
      case "1":
        desc += "10ft Cord Length, "
        head++;
        break;
    
      default:
        break;
    }

    head++;

    switch (splitSku[head]) {
      case "N":
        desc += "Non-Pass Thru Cord, "
        break;
      case "X":
        desc += "Non-Pass Thru Braided Cord, "
        break;
      case "P":
        desc += "Pass Thru Cord, "
        break;
    
      default:
        break;
    }

    head++;

    switch (splitSku[head]) {
      case "R":
        desc += "Right Cord Exit, "
        break;
      case "L":
        desc += "Left Cord Exit, "
        break;
    
      default:
        break;
    }

    head++

    switch (splitSku[head]) {
      case "W":
        desc += "White Cubes, "
        break;
      case "G":
        desc += "Graphite Cubes, "
        break;
    
      default:
        break;
    }

    head++

    // switch (splitSku[head]) {
    //   case "M":
    //     desc +=  "Shipping from Mexico"
    //     break;
    //   case "C":
    //     desc += "Shipping from China"
    //     break;
    
    //   default:
    //     break;
    // }

    switch (trim ? trim.slice(-2) : " ") {
      case "BR":
        desc += " with a Brass Trim"
        break;
      case "BZ":
        desc += " with a Bronze Trim"
        break;
      case "ST":
        desc += " with a Stainless Trim"
        break;
      case " ":
        
        break;
    
      default:
        break;
    }
    
    return desc
  }

  render() {
    let panel = null;
    let [size2, size3, size4, size5, size6] = img2;
    let [length6, length10] = img6ft;

    if(document.getElementById("toc") && this.props.step <= 5) {
      const children = document.getElementById("toc").childNodes;
      children.forEach(child => {
        child.classList.remove("toc-active");
      });
      children[this.props.step].classList.add("toc-active");
    }

    if (this.props.dashType === "F") {
      size2 = img2;
      size3 = img3;
      size4 = img4;
      size5 = img5;
      size6 = img6;
      length6 = img6ft;
      length10 = img2;
    } else {
      size2 = img2D;
      size3 = img3D;
      size4 = img4D;
      size5 = img5D;
      size6 = img6D;
      length6 = img6ftD;
      length10 = img10ftD;
    }

    switch (this.props.step) {

      case 0:
        panel = 
          <div className="container">
            <h3>Select Type:</h3>
            <div className="container hor">
              <div className="container">
                <input
                  type="radio"
                  name="dash-type"
                  id="radio-fcp"
                  value="F"
                  onChange={(e) => this.props.onPick(e)}
                  checked={this.props.dashType === "F" ? true : false}
                />
                <label htmlFor="radio-fcp" className="label-radio">
                  <img src={imgFCP} className="option" />
                  <p>Furniture Power Center</p>
                </label>
              </div>
              <div className="container">
                <input
                  type="radio"
                  name="dash-type"
                  id="radio-dcp"
                  value="D"
                  onChange={(e) => this.props.onPick(e)}
                  checked={this.props.dashType === "D" ? true : false}
                />
                <label htmlFor="radio-dcp" className="label-radio">
                  <img src={imgDCP} className="option" />
                  <p>Desktop Power Center</p>
                </label>
              </div>
            </div> 
          </div>
        break;
      case 1:
        panel =
          <div className="container">
            <h3>Select Size:</h3>
            <div className="container hor">
              <div className="container">
                <input
                  type="radio"
                  name="cube-number"
                  id="radio2"
                  onChange={(e) => this.props.onChange(e)}
                  value="2"
                  checked={(this.props.numberCubes === 2)}
                />
                <label htmlFor="radio2" className="label-radio">
                  <img src={size2} className="option" />
                  <p>Two Cubes</p>
                </label>
              </div>
              <div className="container">
                <input
                  type="radio"
                  name="cube-number"
                  id="radio3"
                  onChange={(e) => this.props.onChange(e)}
                  value="3"
                  checked={(this.props.numberCubes === 3)}
                />
                <label htmlFor="radio3" className="label-radio">
                  <img src={size3} className="option" />
                  <p>Three Cubes</p>
                </label>
              </div>
              <div className="container">
                <input
                  type="radio"
                  name="cube-number"
                  id="radio4"
                  onChange={(e) => this.props.onChange(e)}
                  value="4"
                  checked={(this.props.numberCubes === 4)}
                />
                <label htmlFor="radio4" className="label-radio">
                  <img src={size4} className="option" />
                  <p>Four Cubes</p>
                </label>
              </div>
              <div className="container">
                <input
                  type="radio"
                  name="cube-number"
                  id="radio5"
                  onChange={(e) => this.props.onChange(e)}
                  value="5"
                  checked={(this.props.numberCubes === 5)}
                />
                <label htmlFor="radio5" className="label-radio">
                  <img src={size5} className="option" />
                  <p>Five Cubes</p>
                </label>
              </div>
              <div className="container">
                <input
                  type="radio"
                  name="cube-number"
                  id="radio6"
                  onChange={(e) => this.props.onChange(e)}
                  value="6"
                  checked={(this.props.numberCubes === 6)}
                />
                <label htmlFor="radio6" className="label-radio">
                  <img src={size6} className="option" />
                  <p>Six Cubes</p>
                </label>
              </div>
            </div>
          </div>
        break;
      case 2:
        panel =
          <div className="container">
            {
              this.props.dashType === "F"
              &&
              <div className="container">
                <h3>Select Cord Side:</h3>
                <div className="container hor">
                  {
                    this.props.numberCubes > 2
                    &&
                    <div className="container">
                      <input
                        type="radio"
                        name="cord-side"
                        id="radio-left"
                        value="Left"
                        onChange={(e) => this.props.onCheck(e)}
                        checked={this.props.isCordLeft}
                      />
                      <label htmlFor="radio-left" className="label-radio">
                        <img src={imgLeft} className="option" />
                        <p>Left Side Cord</p>
                      </label>
                    </div>
                  }
                  <div className="container">
                    <input
                      type="radio"
                      name="cord-side"
                      id="radio-right"
                      value="Right"
                      onChange={(e) => this.props.onCheck(e)}
                      checked={!this.props.isCordLeft}
                    />
                    <label htmlFor="radio-right" className="label-radio">
                      <img src={imgRight} className="option" />
                      <p>Right Side Cord</p>
                    </label>
                  </div>
                </div> 
              </div>
            } 
            <h3>Cord Size:</h3>
            <div className="container hor">
              <div className="container">
                <input
                  type="radio"
                  name="cord-size"
                  id="six-foot"
                  value="6"
                  onChange={(e) => this.props.onCheck(e)}
                  checked={this.props.cordLength === 6 ? true : false}
                />
                <label htmlFor="six-foot" className="label-radio">
                  <img src={length6} className="option" />
                  <p>6' Cord</p>
                </label>
              </div>
              <div className="container">
                <input
                  type="radio"
                  name="cord-size"
                  id="ten-foot"
                  value="10"
                  onChange={(e) => this.props.onCheck(e)}
                  checked={this.props.cordLength === 10 ? true : false}
                />
                <label htmlFor="ten-foot" className="label-radio">
                  <img src={length10} className="option" />
                  <p>10' Cord</p>
                </label>
              </div>
            </div>  
          </div> 
        break;
      case 3:
        panel = 
          <div className="container">
            <h3>Color Options:</h3>
            <p>Choose cube color:</p>
            <div className="container hor">
              <input
                type="radio"
                name="cube-color"
                id="radio-white"
                value="--white"
                onChange={(e) => this.handleColorChange(e.target.value)}
                checked={this.state.color === "white" ? true : false}
              ></input>
              <label htmlFor="radio-white" className="label-radio">
                <div className="color-choice" color="white"></div>
                <p>White</p>
              </label>
              <input
                type="radio"
                name="cube-color"
                id="radio-graphite"
                value="--graphite"
                onChange={(e) => this.handleColorChange(e.target.value)}
                checked={this.state.color === "graphite" ? true : false}
              ></input>
              <label htmlFor="radio-graphite" className="label-radio">
                <div className="color-choice" color="graphite"></div>
                <p>Graphite</p>
              </label>
            </div>
            {
              this.props.dashType === "F"
              &&
              <div className="container">
                <p>Choose Trim color:</p>
                <div className="container hor">
                  <input
                    type="radio"
                    name="trim-color"
                    id="radio-default"
                    value="--default"
                    onChange={(e) => {this.props.onTrim(e.target.value); this.setState({trim: e.target.value.slice(2)})}}
                    checked={this.state.trim === "default" ? true : false}
                  ></input>
                  <label htmlFor="radio-default" className="label-radio">
                    <div className="color-choice" color="default"></div>
                    <p>Default</p>
                  </label>
                  <input
                    type="radio"
                    name="trim-color"
                    id="radio-brass"
                    value="--brass"
                    onChange={(e) => {this.props.onTrim(e.target.value); this.setState({trim: e.target.value.slice(2)})}}
                    checked={this.state.trim === "brass" ? true : false}
                  ></input>
                  <label htmlFor="radio-brass" className="label-radio">
                    <div className="color-choice" color="brass"></div>
                    <p>Brass</p>
                  </label>
                  <input
                    type="radio"
                    name="trim-color"
                    id="radio-bronze"
                    value="--bronze"
                    onChange={(e) => {this.props.onTrim(e.target.value); this.setState({trim: e.target.value.slice(2)})}}
                    checked={this.state.trim === "bronze" ? true : false}
                  ></input>
                  <label htmlFor="radio-bronze" className="label-radio">
                    <div className="color-choice" color="bronze"></div>
                    <p>Bronze</p>
                  </label>
                  <input
                    type="radio"
                    name="trim-color"
                    id="radio-stainless"
                    value="--stainless"
                    onChange={(e) => {this.props.onTrim(e.target.value); this.setState({trim: e.target.value.slice(2)})}}
                    checked={this.state.trim === "stainless" ? true : false}
                  ></input>
                  <label htmlFor="radio-stainless" className="label-radio">
                    <div className="color-choice" color="stainless"></div>
                    <p>Stainless</p>
                  </label>
                </div>
              </div>
            }
          </div>   
        break;
      case 4:
        panel =
          <div className="container">
            <h3>Select Cord Pass-Thru:</h3>
            <div className="container hor">
              {
                this.props.record.recep + this.props.record.switch <= 2
                &&
                this.props.dashType === "F"
                &&
                <div className="container">
                  <input
                    type="radio"
                    name="cord-pass"
                    id="radio-pass"
                    value={this.props.dashType === "F" ? "P" : "B" }
                    onChange={(e) => this.props.onPass(e)}
                    checked={(this.props.passThru === "P" || this.props.passThru === "B") ? true : false}
                  />
                  <label htmlFor="radio-pass" className="label-radio">
                    <img src={imgPass} className="option" />
                    <p>Pass-Thru Cord</p>
                  </label>
                </div>
              }
              <div className="container">
                <input
                  type="radio"
                  name="cord-pass"
                  id="radio-non"
                  value={this.props.dashType === "F" ? "N" : "X" }
                  onChange={(e) => this.props.onPass(e)}
                  checked={(this.props.passThru === "N" || this.props.passThru === "X") ? true : false}
                />
                <label htmlFor="radio-non" className="label-radio">
                  <img src={this.props.dashType === "F" ? imgNonPass : imgNonPassBraid} className="option" />
                  <p>
                    <span>No Pass-Thru<br/></span>
                    {
                      this.props.dashType !== "F"
                      &&
                      <span> Braided</span>
                    }
                    <span> Cord</span>
                  </p>
                </label>
              </div>
            </div> 
            <h3>Shipping to:</h3>
            <div className="container hor">
                <div className="container">
                  <input
                    type="radio"
                    name="country-code"
                    id="mexico"
                    value="M"
                    onChange={(e) => this.props.onCountry(e)}
                    checked={this.props.countryCode === "M" ? true : false}
                  />
                  <label htmlFor="mexico" className="label-radio">
                    <img src={imgNA} className="option" />
                    <p>North America</p>
                  </label>
                </div>
                {
                  this.props.dashType === "F"
                  &&
                  <div className="container">
                    <input
                      type="radio"
                      name="country-code"
                      id="china"
                      value="C"
                      onChange={(e) => this.props.onCountry(e)}
                      checked={this.props.countryCode === "C" ? true : false}
                    />
                    <label htmlFor="china" className="label-radio">
                      <img src={imgOther} className="option" />
                      <p>China</p>
                    </label>
                  </div>
                }
            </div>  
            <div className="container">
              <label htmlFor="quantity">
                <h3>Quantity:</h3>
              </label>
              <input 
                type="number" 
                id="quantity" 
                className="btn-general"
                name="quantity" 
                min="1" 
                max="999"
                placeholder="1" 
                value={this.props.qty}
                onChange={(e) => {this.props.onQty(e)}}
              />
            </div>
          </div> 
        break;
      case 5:
        panel = 
            <div className="container">
              <div className="container">
                <h3>Current SKU:</h3>
                <p>{this.props.sku}</p>
                {
                  this.props.trimSku !== " "
                  &&
                  <div className="container">
                    <h3>Trim SKU:</h3>
                    <p>{this.props.trimSku}</p>
                  </div>
                }
                <h3>Quantity:</h3>
                <p>{this.props.qty}</p>
              </div>
              {
                this.props.skus.length !== 0
                &&
                <div className="container">
                  <h3>Previous SKUs:</h3>
                  {this.props.skus.map((sku, id) => {
                    return (<p key={id}>
                              <span>SKU #{(id + 1).toString() + ": " + sku.sku}</span>
                              {
                                sku.trim !== " "
                                &&
                                <span> & Trim: {sku.trim}</span>
                              }
                              <span> Qty: {sku.qty}</span>
                            </p>
                    )
                  })}
                </div>
              }
              <div className="container hor">
                <button className="btn-general" onClick={() => {this.props.createNewDash()}}>
                  Create another
                </button>
                {
                  this.props.dashType === "F"
                  &&
                  this.props.numberCubes > 2
                  &&
                  this.props.isMirrorOk
                  &&
                  <button className="btn-general" onClick={() => {this.props.createNewDash("mirror")}}>
                    Create mirror
                  </button>
                }
              </div>
              <div className="container pdf-form">
                <h3>PDF Contact Info (Optional):</h3>
                <label>First Name:</label>
                <input 
                  type="text" 
                  className="btn-general"
                  name="first" 
                  onChange={(e) => this.handleTextChange(e)} 
                  value={this.state.fName}
                ></input>
                <label>Last Name:</label>
                <input 
                  type="text" 
                  className="btn-general"
                  name="last" 
                  onChange={(e) => this.handleTextChange(e)} 
                  value={this.state.lName} 
                  required
                ></input>
                <label>Email:</label>
                <input 
                  type="email" 
                  className="btn-general"
                  name="email" 
                  onChange={(e) => this.handleTextChange(e)} 
                  value={this.state.email} 
                  required
                ></input>
                <label>Phone:</label>
                <input 
                  type="tel" 
                  className="btn-general"
                  name="phone" 
                  onChange={(e) => this.handleTextChange(e)}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  value={this.state.phone}
                ></input>
                {/* <label>Notes:</label>
                <textarea 
                  type="textarea" 
                  className="btn-general"
                  name="notes" 
                  onChange={(e) => this.handleTextChange(e)} 
                  value={this.state.notes} 
                ></textarea> */}

                {/* <PDFViewer style={{width: "60vw", height: "400px"}}>
                  <MyDocument 
                    data={this.props} 
                    fName={this.state.fName} 
                    lName={this.state.lName} 
                    email={this.state.email}
                    phone={this.state.phone}
                    calcDesc={this.calcDesc}
                  />
                </PDFViewer> */}

                
                <PDFDownloadLink 
                  document={
                    <MyDocument 
                      data={this.props} 
                      fName={this.state.fName} 
                      lName={this.state.lName} 
                      email={this.state.email}
                      phone={this.state.phone}
                      calcDesc={this.calcDesc}
                    />
                  } 
                  fileName="Legrand-Dash-SKUs.pdf" 
                  className="btn-general"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download PDF"
                  }
                </PDFDownloadLink>
              </div>
            </div>
        break;
    
      default:
        break;
    }

    return (
      <div className="container">
        {panel}
      </div>
    )
  }
}
export default Settings