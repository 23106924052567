import React from "react";

function Header(props) {
  return (
    <div id="header" className="container hor">
      <div id="logo">
        <a href="https://www.legrand.us/" className="container">
          <img src="https://www.legrand.us/_ui/responsive/theme-lambda/images/logo/logo-legrand.svg" />
        </a>
      </div>
      <div id="title" className="container">
        <h1>Dash&trade; Configurator</h1>
        <div id="toc" className="container hor">
          <div className="toc-item">
            Type
          </div>
          <div className="toc-item">
            Size
          </div>
          <div className="toc-item">
            Cord
          </div>
          <div className="toc-item">
            Cubes
          </div>
          <div className="toc-item">
            Misc
          </div>
          <div className="toc-item">
            Finish
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header