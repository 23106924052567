import React from "react";
//Stylesheet
//Components
import Cord from "./Cord";
import CubeControl from "./CubeControl";

class Housing extends React.Component {
  render() {
    return (
      <div className="container">
        <h3>Current Build:</h3>
        <div id="build" className="container">
          <Cord side="left" 
            isCordLeft={this.props.isCordLeft}
            switches={this.props.switches}
            dashType={this.props.dashType}
          />
          <CubeControl 
            dashType={this.props.dashType}
            numberCubes={this.props.numberCubes} 
            currentCube={this.props.currentCube}
            isCordLeft={this.props.isCordLeft}
            data={this.props.data}
            calcSku={(cubes) => {this.props.calcSku(cubes)}}
          />
          <Cord side="right" 
            isCordLeft={this.props.isCordLeft}
            switches={this.props.switches}
            dashType={this.props.dashType}
          />
        </div>
      </div>
    )
  }
}
export default Housing