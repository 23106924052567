import React from "react";
//Components
import Cube from "./Cube";

class CubeControl extends React.Component {

  componentDidMount() {
    const newData = this.props.data;

    for (let i = 0; i < this.props.data.length; i++) {
      if (i >= this.props.numberCubes) {
        newData[i].isActive = false;
      } else {
        newData[i].isActive = true;
      }
    }
  }

  render() {
    const newData = this.props.data;
    let trim = this.props.dashType === "F" ? "trim" : "trim-D"
    let rotation = window.innerWidth > 750 ? "row" : "column";

    return (
      <div className="container">
        <div 
          id={trim}
          className="container" 
          style={{flexDirection: rotation}}
          // OLD "BUILD TO CORD" LOGIC
          // style={
          //   this.props.isCordLeft ? 
          //     {flexDirection: rotation + "-reverse"} 
          //     : 
          //     {flexDirection: rotation}
          // }
        >
          {newData.map(
            (cube, id) => {
              let isCurrent = false;
              if (id === this.props.currentCube) {
                isCurrent = true;
              }

              return (
                <Cube
                  key={id}
                  location={cube.location}
                  contains={cube.contains}
                  isActive={cube.isActive}
                  isCurrent={isCurrent}
                />
              )
            }
          )}
        </div>
      </div>
    )
  }
}
export default CubeControl